:root {
  --max-width: 1100px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono',
    'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;

  --success-color: #1b5e20;
  --success-background-color-blue: #1976d2;
  --success-background-color-brown: #5d4037;
  --success-background-color-orange: #f57c00;
  --success-background-color-pink: #c2185b;
  --success-background-color-purple: #7b1fa2;
  --success-background-color-yellow: #fbc02d;
  --failure-color: #b71c1c;
  --success-failure-text-color: #fff;
  --success-bright-background-text-color: #000000;
  --height-header: 56px;
  --height-footer: 40px;
  --combined-height-of-header-footer: calc(var(--height-header) + var(--height-footer));

  --background-fatal-error-info: #fff;
  --color-fatal-error-info: #000;
  --border-radius-fatal-error-info: 5px;

  --peripheral-section-bg-color: #fff2;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: var(--background-start-rgb);
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.contentContainer {
  background-color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: 100dvh;
  max-height: 100dvh;
}
